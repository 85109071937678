import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

export const routescopy = [
  {
    path: '/login',
    name: 'Login',
    meta: {
      hidden: true,
      title: '登录',
      hiddleTag: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/404',
    name: '404',
    meta: {
      hidden: true,
      title: '找不到页面',
    },
    component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
  },
  {
    path: '/403',
    name: '403',
    meta: {
      hidden: true,
      title: '没有权限',
    },
    component: () => import(/* webpackChunkName: "403" */ '../views/403.vue'),
  },
]

export const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    meta: { hidden: true, hiddleTag: true },
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: { title: '首页', icon: 'home', affix: true },
        component: () => import('../views/Dashboard.vue'),
      },
      {
        path: '/404',
        name: '404',
        meta: {
          hidden: true,
          title: '找不到页面',
        },
        component: () =>
          import(/* webpackChunkName: "404" */ '../views/404.vue'),
      },
      {
        path: '/403',
        name: '403',
        meta: {
          hidden: true,
          title: '没有权限',
        },
        component: () =>
          import(/* webpackChunkName: "403" */ '../views/403.vue'),
      },
    ],
  },
  {
    path: '/patients',
    name: 'patients',
    meta: { title: '病历信息', icon: 'patients' },
    redirect: '/patients/patientLists',
    component: Home,
    children: [
      {
        path: 'patientLists',
        name: 'patientLists',
        meta: { title: '患者档案' },
        component: () => import('@/views/patient/index.vue'),
      },
      {
        path: 'patientDetails/:id?',
        name: 'patientDetails',
        meta: { title: '病历详情', hidden: true },
        component: () => import('@/views/patient/Details/index.vue'),
      },
    ],
  },
  {
    path: '/appointment',
    name: 'appointment',
    meta: { title: '排班管理', icon: 'appointment' },
    redirect: '/appointment/appointmentList',
    component: Home,
    children: [
      {
        path: 'appointmentList',
        name: 'appointmentList',
        meta: { title: '排班列表' },
        component: () => import('@/views/appointment/index.vue'),
      },
      {
        path: 'templateList',
        name: 'templateList',
        component: () => import('@/views/appointment/templateList.vue'),
      },
      {
        path: 'TXPlan',
        name: 'TXPlan',
        meta: { title: '透析计划' },
        component: () => import('@/views/appointment/TXPlan.vue'),
      },
    ],
  },
  {
    path: '/TXjilu',
    name: 'TXjilu',
    meta: { title: '透析治疗', icon: 'treatment' },
    redirect: '/TXjilu/record',
    component: Home,
    children: [
      {
        path: 'prepare',
        name: 'prepare',
        meta: { title: '透前准备' },
        component: () => import('@/views/TXjilu/prepare/index.vue'),
      },
      {
        path: 'record',
        name: 'record',
        meta: { title: '透析记录' },
        component: () => import('@/views/TXjilu/index.vue'),
      },
      {
        path: 'report',
        name: 'report',
        meta: { title: '透析报告' },
        component: () => import('@/views/dialysisReport/index.vue'),
      },
      {
        path: 'count',
        name: 'count',
        meta: { title: '透析统计' },
        component: () => import('@/views/TXjilu/count/index.vue'),
      },
      // {
      //   path: 'medicalAdvice',
      //   name: 'medicalAdvice',
      //   meta: { title: '预开医嘱' },
      //   component: () => import('@/views/patient/Details/index.vue'),
      // },
    ],
  },
  {
    path: '/stock',
    name: 'stock',
    meta: { title: '库存管理', icon: 'stock' },
    redirect: '/stock/Basedirectory',
    component: Home,
    children: [
      {
        path: 'Basedirectory',
        name: 'Basedirectory',
        meta: { title: '基本目录' },
        component: () => import('@/views/stock/index.vue'),
      },
      // {
      //   path: 'depmtDrug',
      //   name: 'depmtDrug',
      //   meta: { title: '科室药品' },
      //   component: () => import('@/views/depmtDrug/index.vue'),
      // },
      {
        path: 'consumables',
        name: 'consumables',
        meta: { title: '科室耗材' },
        component: () => import('@/views/consumables/index.vue'),
      },
    ],
  },
  {
    path: '/equipment',
    name: 'equipment',
    meta: { title: '设备管理', icon: 'equipment' },
    redirect: '/equipment/SchedulingList',
    component: Home,
    children: [
      {
        path: 'bed',
        name: 'Bed',
        meta: { title: '床位设置' },
        component: () => import('@/views/equipment/bed/index.vue'),
      },
      {
        path: 'machine',
        name: 'machine',
        meta: { title: '机器设置' },
        component: () => import('@/views/equipment/machine/index.vue'),
      },
    ],
  },
  // {
  //   path: '/stack',
  //   name: 'stack',
  //   meta: { title: '费用管理', icon: 'cost' },
  //   redirect: '/stack/charge',
  //   component: Home,
  //   children: [
  //     {
  //       path: 'SchedulingList',
  //       name: 'SchedulingList',
  //       meta: { title: '费用统计' },
  //       component: () => import('@/views/patient/index.vue'),
  //     },
  //     {
  //       path: 'charge',
  //       name: 'charge',
  //       meta: { title: '收费组套' },
  //       component: () => import('@/views/stack/index.vue'),
  //     },
  //     {
  //       path: 'basics',
  //       name: 'basics',
  //       meta: { title: '基础组套' },
  //       component: () => import('@/views/stack/basics.vue'),
  //     },
  //   ],
  // },
  {
    path: '/system',
    name: 'system',
    meta: { title: '系统管理', icon: 'system' },
    redirect: '/system/userList',
    component: Home,
    children: [
      {
        path: 'userList',
        name: 'userList',
        meta: { title: '用户管理' },
        component: () => import('@/views/system/user/index.vue'),
      },
      {
        path: 'menuList',
        name: 'menuList',
        meta: { title: '菜单管理' },
        component: () => import('@/views/system/menu/index.vue'),
      },
      {
        path: 'permission',
        name: 'permission',
        meta: { title: '权限管理' },
        component: () => import('@/views/system/permission/index.vue'),
      },
      {
        path: 'role',
        name: 'role',
        meta: { title: '角色管理' },
        component: () => import('@/views/system/role/index.vue'),
      },
      {
        path: 'consent',
        name: 'consent',
        meta: { title: '知情同意书模板' },
        component: () => import('@/views/system/consent/index.vue'),
      },
      
      // {
      //   path: 'dialysisPlan',
      //   name: 'dialysisPlan',
      //   meta: { title: '字典管理' },
      //   component: () => import('@/views/patient/Details/index.vue'),
      // },
      {
        path: 'settingUP',
        name: 'settingUP',
        meta: { title: '系统设置' },
        component: () => import('@/views/system/systsettings/settingUP.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      hidden: true,
      title: '登录',
      hiddleTag: true,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
